import Swiper from "swiper";
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/bannerVedio.mp4'),
            //             phoneImg: require('./images/mobile/bannerVedio.mp4'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人高性能版',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIC T6 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP67',
            //                     sort: 3
            //                 }, {
            //                     descName: '≥6h',
            //                     sort: 4
            //                 }, {
            //                     descName: '≤340kg',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 6
            //                 }, {
            //                     descName: '防护等级',
            //                     sort: 7
            //                 }, {
            //                     descName: '续航时间',
            //                     sort: 8
            //                 }, {
            //                     descName: '整备质量',
            //                     sort: 9
            //                 }],
            //             type: 'vedio',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [
            //                 {
            //                     descName: '性能底盘',
            //                     sort: 1
            //                 }, {
            //                     descName: '整体构造上采用模块化设计及组装，实现机身控制的动态调节，使整机具备极致的平稳性、支撑性与通过性，通过高精悬挂实现高频减震功能，不仅能够保障检测过程中画面的平稳和数据的准确，还能够更加稳定地承载防爆仓，进一步提升巡检性能。',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: '优化',
            //                     sort: 1
            //                 }, {
            //                     descName: '降低',
            //                     sort: 2
            //                 }, {
            //                     descName: '减少',
            //                     sort: 3
            //                 }, {
            //                     descName: '适应',
            //                     sort: 4
            //                 }, {
            //                     descName: '俯仰侧倾',
            //                     sort: 5
            //                 }, {
            //                     descName: '高频震动',
            //                     sort: 6
            //                 }, {
            //                     descName: '溜坡距离',
            //                     sort: 7
            //                 }, {
            //                     descName: '铺装及非铺装路面',
            //                     sort: 8
            //                 }, {
            //                     descName: '高精悬挂，稳如泰山',
            //                     sort: 9
            //                 }, {
            //                     descName: '高精度悬挂，高频减震，超稳驻地，在横滑、坡度、颠簸路面和斜面等环境下行走自如。',
            //                     sort: 10
            //                 },],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '边缘模块',
            //                     sort: 1
            //                 }, {
            //                     descName: '毫秒级',
            //                     sort: 2
            //                 }, {
            //                     descName: '强力算力升级',
            //                     sort: 3
            //                 }, {
            //                     descName: '识别速度',
            //                     sort: 4
            //                 }, {
            //                     descName: '鹰眼识别，精准无暇',
            //                     sort: 5
            //                 }, {
            //                     descName: '算力升级，图像识别算法智慧演进，毫秒级识别速度提升。',
            //                     sort: 6
            //                 },],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcImg: require('./images/pc/banner5.jpg'),
            //             phoneImg: require('./images/mobile/banner5.jpg'),
            //             descList: [
            //                 {
            //                     descName: '小于20%',
            //                     sort: 1
            //                 }, {
            //                     descName: '30+',
            //                     sort: 2
            //                 }, {
            //                     descName: '厘米级',
            //                     sort: 3
            //                 }, {
            //                     descName: '探测距离衰减',
            //                     sort: 4
            //                 }, {
            //                     descName: '场景拓展',
            //                     sort: 5
            //                 }, {
            //                     descName: '精准定位',
            //                     sort: 6
            //                 }, {
            //                     descName: '三维定位，洞若烛照',
            //                     sort: 7
            //                 }, {
            //                     descName: 'RTK定位系统场景优化，解锁特殊场景，厘米级精准锁定，巡检覆盖无盲区。',
            //                     sort: 8
            //                 },],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcImg: require('./images/pc/banner6.jpg'),
            //             phoneImg: require('./images/mobile/banner6.jpg'),
            //             descList: [
            //                 {
            //                     descName: '轻量化设计',
            //                     sort: 1
            //                 }, {
            //                     descName: '更小体积，具备更强空间适应能力，可在狭小空间自如运作',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcImg: require('./images/pc/banner7.jpg'),
            //             phoneImg: require('./images/mobile/banner7.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆散热系统',
            //                     sort: 1
            //                 }, {
            //                     descName: '解决产品内部高温问题',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcImg: require('./images/pc/banner8.jpg'),
            //             phoneImg: require('./images/mobile/banner8.jpg'),
            //             descList: [
            //                 {
            //                     descName: '隔爆型激光雷达',
            //                     sort: 1
            //                 }, {
            //                     descName: '雷达探测距离衰减小于20%',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcImg: require('./images/pc/banner9.jpg'),
            //             phoneImg: require('./images/mobile/banner9.jpg'),
            //             descList: [
            //                 {
            //                     descName: '超声波全向防撞系统',
            //                     sort: 1
            //                 }, {
            //                     descName: '增加产品自身防护及对周边物体的防护，检测距离可调',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcImg: require('./images/pc/banner10.jpg'),
            //             phoneImg: require('./images/mobile/banner10.jpg'),
            //             descList: [
            //                 {
            //                     descName: '三代VCU',
            //                     sort: 1
            //                 }, {
            //                     descName: '更多类型的硬件接口能满足更多类型传感器的接入需求，提高通讯的抗干扰能力，保证通讯质量',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcImg: require('./images/pc/banner11.jpg'),
            //             phoneImg: require('./images/mobile/banner11.jpg'),
            //             descList: [
            //                 {
            //                     descName: '程控电源系统',
            //                     sort: 1
            //                 }, {
            //                     descName: '增加对各供电线路(非驱动线路)的监控，能灵活组合满足远程控制',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 11
            //         },
            //         {
            //             pcImg: require('./images/pc/banner12.jpg'),
            //             phoneImg: require('./images/mobile/banner12.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人高性能版',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: '规格参数',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '外形尺寸(长×宽×高)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: '行走速度',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: '越障能力',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '充电时间',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: '主导航方式',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: '整备质量',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: '防护等级',
            //                     sort: 9
            //                 }, {
            //                     descName: '爬坡能力',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: '涉水深度',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: '续航时间',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: '重复导航定位精度',
            //                     sort: 13
            //                 }, {
            //                     descName: '1050mm×860mm×960mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'Ex d IIC T6 Gb',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '0-1.5m/s，速度可调',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '150mm',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: '2小时充电量大于80%(快充)',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: 'SLAM激光导航和SLAM激光定位',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '≤340kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: 'IP67',
            //                     sort: 21
            //                 }, {
            //                     descName: '≤25°',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '200mm',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '行驶速度保持在 1m/s 时不小于6h，待机不小于24h',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: '≤±10mm',
            //                     sort: 25
            //                 }],
            //              type: 'image',
            //             sort: 12
            //         },
            //         {
            //             pcImg: require('./images/pc/banner13.jpg'),
            //             phoneImg: require('./images/mobile/banner13.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人高性能版',
            //                     sort: 1
            //                 }, {
            //                     descName: '安全高性能，巡检新标杆',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 13
            //         }
            //     ],
            elmentList:[],
            aboutswiper: null,
        }
    },
    mounted() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                this.aboutSwiper();
                // 取消这个方式加载视频
                if (!this.$store.state.pageset.pcshow) {
                    let video = document.getElementById('videoOne');
                    video.play();
                  }
            }, 100);
        });
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.aboutswiper.destroy();
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        // 初始化轮播图
        aboutSwiper() {
            let that = this;
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' +
                            '<p class="p-name">' + (that.elmentList[index + 5].descList[0].descName) +
                            '</p>' +
                            '<p class="p-dec">' + (that.elmentList[index + 5].descList[1].descName) +
                            '</p>'
                            + '</span>';
                    },
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                effect: 'fade',//切换效果
                fadeEffect: {
                    crossFade: true  //开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                    //false  关闭淡出。过渡时，原slide透明度为1（不淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                },
            });
        },
        openPdf() {
             this.$router.push('/pdfView')
        },
    }
}