<template>
  <!-- 防爆化工轮式巡检机器人高性能版 -->
  <div class="product wheeledHigh" v-if="elmentList.length">
    <div class="banner_video" :id="'elm' + elmentList[0].id">
      <!-- 滑动切换内容 -->
      <video
        autoplay="autoplay"
        loop
        muted
        preload
        class="video"
        v-if="this.$store.state.pageset.pcshow"
        type="video/mp4"
        :src="elmentList[0].pcImg"
        :ref="'video' + elmentList[0].pcElementId"
      ></video>
      <video
        v-else
        autoplay
        muted
        playsinline
        loop
        x5-video-player-type="h5-page"
        x-webkit-airplay="true"
        webkit-playsinline="true"
        x5-video-player-fullscreen="false"
        class="video"
        type="video/mp4"
        :src="elmentList[0].phoneImg"
        :ref="'video' + elmentList[0].phoneElementId"
        :id="'videoOne'"
      ></video>
      <!-- <div class="video-div" id="mobileQu"></div> -->
      <div class="img-con">
        <div class="title">
          <h1>{{ elmentList[0].descList[0].descName }}</h1>
        </div>
      </div>
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-par">
          <div class="dec-par-item" v-for="index in 4" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[0].descList[index].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[0].descList[index + 4].descName }}
            </p>
          </div>
        </div>
        <div>
          <el-button
            size="small"
            style="margin-right: 20px"
            class="btn-div-experience btn-div-order"
            plain
            @click="retention"
            >立即订购</el-button
          >
          <el-button
            size="small"
            class="btn-div-experience btn-div-test"
            plain
            @click="retention"
            >预约体验</el-button
          >
        </div>
        <div class="scroll-wrap">
          <div class="remark">
            本页面视频中出现的产品仅供参考，产品的外观、配置、型号等细节可能有所差异，具体请以当地实际交付为准。
          </div>
        </div>
      </div>
    </div>
    <div class="wheeledHigh-content">
      <div class="content-one" :id="'elm' + elmentList[1].id">
        <img
          :src="elmentList[1].pcImg"
          v-if="this.$store.state.pageset.pcshow"
        />
        <img :src="elmentList[1].phoneImg" v-else />
        <div class="dec">
          <div class="dec-left" v-animate="'queue-bottom'">
            <p class="p1">{{ elmentList[1].descList[0].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >立即订购</el-button
            >
          </div>
          <div class="dec-right" v-animate="'queue-bottom'">
            <p class="p2">
              {{ elmentList[1].descList[1].descName }}
            </p>
          </div>
          <div class="phone-order" v-if="!this.$store.state.pageset.pcshow">
            <el-button
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >立即订购</el-button
            >
          </div>
        </div>
      </div>
      <div class="content-two" :id="'elm' + elmentList[2].id">
        <img
          :src="elmentList[2].pcImg"
          v-if="this.$store.state.pageset.pcshow"
        />
        <img :src="elmentList[2].phoneImg" v-else />
        <div class="parcon">
          <div class="dec-par-item" v-for="index in 4" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[2].descList[index - 1].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[2].descList[index + 3].descName }}
            </p>
          </div>
        </div>
        <div class="dec">
          <div class="dec-left">
            <p>{{ elmentList[2].descList[8].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >立即订购</el-button
            >
          </div>
          <div class="dec-right">
            <p>
              {{ elmentList[2].descList[9].descName }}
            </p>
          </div>
          <el-button
            v-if="!this.$store.state.pageset.pcshow"
            size="small"
            class="btn-div-experience black-btn"
            plain
            @click="retention"
            >立即订购</el-button
          >
        </div>
      </div>
      <div class="content-two" :id="'elm' + elmentList[3].id">
        <img
          :src="elmentList[3].pcImg"
          v-if="this.$store.state.pageset.pcshow"
        />
        <img :src="elmentList[3].phoneImg" v-else />
        <div class="parcon">
          <div class="dec-par-item" v-for="index in 2" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[3].descList[index - 1].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[3].descList[index + 1].descName }}
            </p>
          </div>
        </div>
        <div class="dec">
          <div class="dec-left">
            <p>{{ elmentList[3].descList[4].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >立即订购</el-button
            >
          </div>
          <div class="dec-right">
            <p>
              {{ elmentList[3].descList[5].descName }}
            </p>
          </div>
          <el-button
            v-if="!this.$store.state.pageset.pcshow"
            size="small"
            class="btn-div-experience black-btn"
            plain
            @click="retention"
            >立即订购</el-button
          >
        </div>
      </div>
      <div class="content-two" :id="'elm' + elmentList[4].id">
        <img
          :src="elmentList[4].pcImg"
          v-if="this.$store.state.pageset.pcshow"
        />
        <img :src="elmentList[4].phoneImg" v-else />
        <div class="parcon">
          <div
            class="dec-par-item"
            v-for="index in 3"
            :key="index"
            style="color: black !important"
          >
            <p class="dec-par-item-title" v-if="$store.state.pageset.pcshow">
              {{ elmentList[4].descList[index - 1].descName }}
            </p>
            <p class="dec-par-item-dec" v-if="$store.state.pageset.pcshow">
              {{ elmentList[4].descList[index + 2].descName }}
            </p>
          </div>
        </div>
        <div class="dec">
          <div class="dec-left">
            <p>{{ elmentList[4].descList[6].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >立即订购</el-button
            >
          </div>
          <div class="dec-right">
            <p>
              {{ elmentList[4].descList[7].descName }}
            </p>
          </div>
          <el-button
            v-if="!this.$store.state.pageset.pcshow"
            size="small"
            class="btn-div-experience black-btn"
            plain
            @click="retention"
            >立即订购</el-button
          >
        </div>
      </div>

      <!-- 轮播图 -->
      <div class="content-fwo">
        <p class="p-title" v-animate="'queue-bottom'">功能升级</p>
        <div class="mySwiper img-swiper">
          <div class="swiper-wrapper screen-swiper">
            <div class="swiper-slide" v-for="index in 6" :key="index" :id="'elm' + elmentList[index + 4].id">
              <!-- 滑动切换内容 -->
              <img
                :src="elmentList[index + 4].pcImg"
                v-if="$store.state.pageset.pcshow"
              />
              <img :src="elmentList[index + 4].phoneImg" v-else />
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
        <el-button
          v-animate="'queue-bottom'"
          size="small"
          class="btn-div-experience black-btn"
          plain
          @click="retention"
          >立即订购</el-button
        >
      </div>

      <!-- 产品参数 -->
      <div class="content-par-remark" :id="'elm' + elmentList[11].id">
        <div class="img">
          <img
            :src="elmentList[11].pcImg"
            v-if="$store.state.pageset.pcshow"
            style="height: 580px; width: 580px"
          />
          <img :src="elmentList[11].phoneImg" v-else />
        </div>
        <div class="dec">
          <p class="p1">{{ elmentList[11].descList[0].descName }}</p>
          <p class="p1">{{ elmentList[11].descList[1].descName }}</p>
          <div class="dec-con" v-animate="'queue-bottom'">
            <div class="dec-con-left">
              <ul class="des-ui">
                <li v-for="index in 6" :key="index">
                  <p>{{ elmentList[11].descList[index + 1].descName }}</p>
                  <p>{{ elmentList[11].descList[index + 13].descName }}</p>
                </li>
              </ul>
            </div>
            <div class="dec-con-right">
              <ul class="des-ui">
                <li v-for="index in 6" :key="index">
                  <p>{{ elmentList[11].descList[index + 7].descName }}</p>
                  <p>{{ elmentList[11].descList[index + 19].descName }}</p>
                </li>
              </ul>
            </div>
          </div>
          <el-button
            v-animate="'queue-bottom'"
            size="small"
            class="btn-div-experience white-btn"
            plain
            @click="openPdf"
            >产品手册</el-button
          >
        </div>
      </div>

      <div class="content-par-order" :id="'elm' + elmentList[12].id">
        <div class="img" v-if="!this.$store.state.pageset.pcshow">
          <img
            :src="elmentList[12].phoneImg"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="dec" v-animate="'queue-bottom'">
          <p>{{ elmentList[12].descList[0].descName }}</p>
          <p>{{ elmentList[12].descList[1].descName }}</p>
          <div>
            <el-button
              size="small"
              style="background-color: black; color: #fff"
              class="btn-div-experience"
              plain
              @click="retention"
              >立即订购</el-button
            >
            <el-button
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >预约体验</el-button
            >
          </div>
        </div>
        <div class="img" v-if="this.$store.state.pageset.pcshow">
          <img :src="elmentList[12].pcImg" />
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import wheeledHigh from "./wheeledHigh.js";

export default wheeledHigh;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "wheeledHigh.scss";
@import "../common.scss";

.swiper-pagination {
  position: unset !important;
  text-align: unset !important;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

/deep/.swiper-pagination-bullet {
  width: 155px !important;
  height: 3px !important;
  background: rgb(153, 153, 153) !important;
  margin-right: 22px !important;
  border-radius: unset !important;
  color: rgb(153, 153, 153);
  flex-wrap: nowrap;
  text-align: left;
  .p-name {
    margin-top: 20px;
  }
  .p-dec {
    margin-top: 5px;
  }
}

/deep/.swiper-pagination-bullet-active {
  background: black !important;
  color: black;
  height: 4px !important;
}
@media screen and (max-width: 750px) {
  .swiper-pagination {
    height: 300px;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center;
    justify-content: center;

    margin-top: 0px !important;
  }

  /deep/.swiper-pagination-bullet {
    width: 125px !important;
    height: 2px !important;
    font-size: 12;
    background: rgb(223, 223, 223) !important;
    margin-right: 5px !important;
    margin: 0px 25px !important;
    align-items: center;
  }
  /deep/.swiper-pagination-bullet-active {
    background: rgb(153, 153, 153) !important;
    color: black;
  }
}
</style>
